import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import LogoImg from "../images/logo-header.svg"

const Nav = styled.nav`
  margin-bottom: 1.45rem;
`

const Container = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
  padding: 0 calc((100vw - 1440px - 0.5rem) / 2) 2rem;
  margin: 0;
`

const Item = styled.li`
  color: #ffffff;
  margin: 0 1.45rem;
  @media (max-width: 420px) {
    margin: 0 0.5rem;
  }
`

const Logo = styled.li`
  flex: 1;
  margin: 0 1.45rem;
  margin-right: auto;
`

const linkStyle = css`
  color: #ffffff;
  opacity: 0.6;
  padding: 0 0.75rem 0.25rem;
  font-size: 1em;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
`

type NavLinkProps = {
  to: string
  text: string
}

const NavLink = ({ to, text }: NavLinkProps) => (
  <Link
    to={to}
    css={linkStyle}
    activeStyle={{
      fontWeight: 600,
      borderBottom: "4px solid #71C552",
      opacity: 1,
    }}
  >
    {text}
  </Link>
)

const Navbar = () => (
  <Nav>
    <Container>
      <Logo>
        <Link to="/">
          <LogoImg />
        </Link>
      </Logo>

      <Item>
        <NavLink to="/peru" text="Perú" />
      </Item>

      <Item>
        <NavLink to="/uruguay" text="Uruguay" />
      </Item>
    </Container>
  </Nav>
)

export default Navbar
