import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EventBox from "../components/event-box"
import Hero from "../components/hero"
import Navbar from "../components/navbar"
import withLocation from "../components/with-location"

const EventContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  @media (max-width: 420px) {
    flex-direction: column;
  }
`

const HeadLine = styled.h2`
  max-width: 680px;
  padding: 0 1rem;
  line-height: 1.2;
  margin: 3rem auto;
  font-size: 2.4375em;
  text-align: center;
`

const IndexPage = () => (
  <Layout>
    <SEO title="RISE" />
    <Navbar />
    <Hero />

    <HeadLine>
      Todo lo que tienes que saber sobre el Mercado Financiero 2020
    </HeadLine>

    <EventContainer>
      <EventBox to="/peru/" country="PERÚ" date="05/02/2020" flag="peru" />
      <EventBox
        to="/uruguay/"
        country="URUGUAY"
        date="05/02/2020"
        flag="uruguay"
      />
      <EventBox to="/" country="CHILE" flag="chile" />
    </EventContainer>
  </Layout>
)

export default withLocation(IndexPage)
